export const decimalToODDS = (number: number): number => {
  if (number < 0) {
    return -decimalToODDS(Math.abs(number));
  }
  if (number < 2) {
    return Number((-100 / (number - 1)).toFixed());
  }
  return Number(((number - 1) * 100).toFixed());
};

export function getOddDirection(odd: number) {
  if (odd > 0) {
    return "+";
  }
  return "";
}
