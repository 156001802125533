import { ReduxActionType } from "./types";
import { Odds, Match, PlayerStats } from "./state";

export function setLastOddsAction(odds: Odds) {
  return {
    type: ReduxActionType.SET_LAST_ODDS as ReduxActionType.SET_LAST_ODDS,
    payload: {
      odds,
    },
  };
}

export function pollAction() {
  return {
    type: ReduxActionType.POLL as ReduxActionType.POLL,
    payload: {},
  };
}

export function setMatchAction(match: Match) {
  return {
    type: ReduxActionType.SET_MATCH as ReduxActionType.SET_MATCH,
    payload: {
      match,
    },
  };
}

export function getMatchAndSponsorAction() {
  return {
    type: ReduxActionType.GET_MATCH_AND_SPONSOR as ReduxActionType.GET_MATCH_AND_SPONSOR,
    payload: {},
  };
}

export function setHomeStatsAction(stats: PlayerStats) {
  return {
    type: ReduxActionType.SET_HOME_STATS as ReduxActionType.SET_HOME_STATS,
    payload: {
      stats,
    },
  };
}

export function setAwayStatsAction(stats: PlayerStats) {
  return {
    type: ReduxActionType.SET_AWAY_STATS as ReduxActionType.SET_AWAY_STATS,
    payload: {
      stats,
    },
  };
}
