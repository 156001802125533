import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";

export default function Features() {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView="auto"
      navigation
      breakpoints={{
        1440: { slidesPerView: 3.5 },
      }}
    >
      <SwiperSlide>
        <div className="competitons-item-link-wrapper">
          <a className="competitons-item-link">
            <img src="news1.jpeg" className="competitions-item-image" />
            <h3 className="competition-item-title">
              The Ak & Barak Show | August 11: Braekhus Ready for McCaskill Test
            </h3>
            <span className="competitions-subtitle">Ak & Barak</span>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="competitons-item-link-wrapper">
          <a className="competitons-item-link">
            <img src="news2.jpeg" className="competitions-item-image" />
            <h3 className="competition-item-title">
              JABS with Mannix & Mora | August 10: Should Canelo Wait for
              Saunders?
            </h3>
            <span className="competitions-subtitle">DAZN Presents</span>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="competitons-item-link-wrapper">
          <a className="competitons-item-link">
            <img src="news3.jpeg" className="competitions-item-image" />
            <h3 className="competition-item-title">
              The Ak & Barak Show | August 10: Should Taylor Fight Cyborg?
            </h3>
            <span className="competitions-subtitle">Ak & Barak</span>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="competitons-item-link-wrapper">
          <a className="competitons-item-link">
            <img src="news4.jpeg" className="competitions-item-image" />
            <h3 className="competition-item-title">
              Boxing With Chris Mannix | August 9: Frampton Focused on Future
            </h3>
            <span className="competitions-subtitle">DAZN Presents</span>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="competitons-item-link-wrapper">
          <a className="competitons-item-link">
            <img src="news5.jpeg" className="competitions-item-image" />
            <h3 className="competition-item-title">
              S. Mosley vs. Mora (2010): Hosted by Sergio Mora & Todd Grisham
            </h3>
            <span className="competitions-subtitle">Saturday Fight Live</span>
          </a>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
