import React from "react";
import { motion } from "framer-motion";

import "./index.css";
import ReduxState from "../../../redux/state";
import { getMatchOpponents } from "../../../helpers/match";
import { connect } from "react-redux";
import { getStorageValue } from "../../../helpers/storage.helpers";

interface PlayerStatsNamesStore {
  home: string;
  away: string;
}

type PlayerStatsNamesProps = PlayerStatsNamesStore;

const connector = (state: ReduxState): PlayerStatsNamesStore => {
  const { match } = state;
  if (!match) {
    return {
      home: "PLAYER1",
      away: "PLAYER2",
    };
  }
  const [home, away] = getMatchOpponents(match.name);
  return { home, away };
};

function PlayerStatsNames(props: PlayerStatsNamesProps) {
  const { home, away } = props;
  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 100%)`;

  const innerStyle = {
    background: backgroundGradient,
  };
  return (
    <>
      <motion.div
        className="onyx-player-stats-player-name"
        transition={{ ease: "easeOut", duration: 1 }}
        animate={{
          left: [-500, 0],
        }}
        style={{ justifyContent: "flex-end", ...innerStyle }}
      >
        {home}
      </motion.div>
      <motion.div
        className="onyx-player-stats-player-name"
        transition={{ ease: "easeOut", duration: 1 }}
        animate={{
          right: [-500, 0],
        }}
        style={innerStyle}
      >
        {away}
      </motion.div>
    </>
  );
}

export default connect(connector)(PlayerStatsNames);
