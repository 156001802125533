import ReduxState from "../state";
import { setAwayStatsAction } from "../actions";
import { ReduxActionType } from "../types";
import { keys, includes } from "lodash";
import { getPlayerStats } from "../../helpers/match.helpers";

type State = ReduxState["awayStats"];

type Actions = ReturnType<typeof setAwayStatsAction>;

const initialState: State = {
  wins: 17,
  losses: 0,
  draw: 0,
  ko: 16,
  age: 24,
  height: 57,
  reach: 68,
  neck: 16,
  chest: 40,
  bicep: 15,
  forearm: 14,
  wrist: 7,
  thigh: 22.5,
  calf: 14,
};

export default function awayStatsReducer(
  state: State = initialState,
  action: Actions
): State {
  if (action.type === ReduxActionType.SET_AWAY_STATS) {
    return getPlayerStats(action.payload.stats);
  }
  return state;
}
