/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation } from "swiper";
import { connect } from "react-redux";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import "./App.css";
import Hook from "./MouseMove";
import Appbar from "./components/layout/Appbar";
import Features from "./components/layout/Features";
import Competitions from "./components/layout/Competitions";
import LiveOddsTracker from "./components/LiveOddsTracker";
import Player from "./components/layout/Player";
import { getMatchAndSponsorAction } from "./redux/actions";
import PlayersStats from "./components/layout/PlayersStats";
import {
  GIF_DISPLAY_TIME,
  GIF_DISPLAY_INTERVAL,
  GIF_FIRST_DISPLAY_INTERVAL,
} from "./config";
import SmallCharts from "./components/data-visualisation/SmallCharts";
import ChartsMenu from "./components/layout/ChartsMenu";

SwiperCore.use([Navigation]);

const withStore = connect(null, { getMatchAndSponsorAction });

function App(props) {
  const [names] = useState(["", ""]);
  const [loading, setLoading] = useState(true);
  const [hovered, setMouse] = useState(false);
  const [isBigWidget, setIsBigWidget] = useState(true);
  const [step, setStep] = useState(0);
  const [showSmallCharts, setShowSmallCharts] = useState(false);

  const [displayIntro, setDisplayIntro] = useState(false);
  const [firstDisplayIntro, setFirstDisplayIntro] = useState(true);

  const handleMouseEnter = () => {
    setMouse(true);
  };

  const handleMouseLeave = () => {
    setMouse(false);
  };

  useEffect(() => {
    if (step < 1) {
      const t = setTimeout(() => {
        setStep(1);
      }, 20000);
      return () => clearTimeout(t);
    }
  }, [step]);

  useEffect(() => {
    const letsTry = async () => {
      setLoading(true);

      props.getMatchAndSponsorAction();

      setLoading(false);
    };

    letsTry();
  }, [props]);

  useEffect(() => {
    !firstDisplayIntro && setDisplayIntro(false);
  }, [firstDisplayIntro]);

  useEffect(() => {
    displayIntro &&
      setTimeout(() => {
        firstDisplayIntro && setFirstDisplayIntro(false);
        !firstDisplayIntro && setDisplayIntro(false);
      }, GIF_DISPLAY_TIME);
    !displayIntro &&
      setTimeout(
        () => {
          setDisplayIntro(true);
        },
        firstDisplayIntro ? GIF_FIRST_DISPLAY_INTERVAL : GIF_DISPLAY_INTERVAL
      );
  }, [displayIntro]);

  return (
    <>
      <Appbar />
      <Hook
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      >
        <main className="page-main">
          {displayIntro && (
            <div className="page-main-video-intro">
              <img src={localStorage.getItem("onyx-sponsor-video-intro")} />
            </div>
          )}
          <section className="video-container">
            <h2 className="video-container-title">Boxing</h2>
            <div className="App">
              <div className="onyx-video-player">
                <Player />
                {step === 0 && <PlayersStats />}
                {step === 1 && (
                  <>
                    {!loading && (
                      <LiveOddsTracker
                        names={names}
                        hovered={hovered}
                        step={step}
                        setStep={setStep}
                        setIsBigWidget={setIsBigWidget}
                        isBigWidget={isBigWidget}
                      />
                    )}
                  </>
                )}
                {!loading && showSmallCharts && <SmallCharts names={names} />}
                <ChartsMenu
                  isBigWidget={isBigWidget}
                  setStep={setStep}
                  setIsBigWidget={setIsBigWidget}
                  showSmallCharts={showSmallCharts}
                  setShowSmallCharts={setShowSmallCharts}
                />
              </div>
            </div>
          </section>
          <section className="competitions-container">
            <button
              className="switch-button"
              onClick={() => {
                setIsBigWidget(true);
              }}
            />
            <button
              className="switch-button switch-button--green"
              onClick={() => {
                setIsBigWidget(false);
              }}
            />

            <h2 className="video-container-title">Features</h2>

            <Features />
          </section>
          <section className="features-container">
            <h2 className="video-container-title">Competitions</h2>
            <Competitions />
          </section>
        </main>
      </Hook>
    </>
  );
}
export default withStore(App);
