import axios from "axios";
import { Odds, Match, Sponsor } from "./redux/state";
import { BASE_URL } from "./config";

const url = BASE_URL + "/landing/odds/from-api/";

export async function getLiveOddsRequest(): Promise<Odds> {
  try {
    const res = await axios.get(
      `${url}${localStorage.getItem("onyx-sponsor-live-matchid")}`
    );

    if (res.data.results && !res.data.results.err) {
      return res.data.results;
    } else {
      throw new Error("Error on getting live odds");
    }
  } catch (error) {
    console.error("Error on getting live odds", error);
    throw error;
  }
}

export async function trackRequest() {
  await axios.post(
    BASE_URL + "/tracking",
    {
      match_id: localStorage.getItem("onyx-sponsor-match-id"),
      sponsor_id: localStorage.getItem("onyx-sponsor-id"),
    },
    {
      // headers: { Authorization: AuthStr },
    }
  );
}

export async function getMatchAndSponsorRequest(): Promise<{
  match: Match;
  sponsor: Sponsor;
}> {
  const res = await axios.get(BASE_URL + "/landing/connected-sponsor");
  if (res.data.err) {
    throw new Error(res.data.message);
  } else {
    return res.data;
  }
}
