import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";

export default function Competitions() {
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={4.5}
      navigation
      breakpoints={{
        1440: { slidesPerView: 5.5 },
      }}
    >
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight1.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight2.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight3.jpg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight1.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight4.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight1.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight1.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight1.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="features-item-link-wrapper">
          <a className="features-item-link">
            <div className="competitions-image-wrapper">
              <img src="fight2.jpeg" className="features-item-image" />
            </div>
            <h4 className="competition-item-title">Linares v Fortuna</h4>
          </a>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
