import {
  VictoryLine,
  VictoryScatter,
  VictoryGroup,
  VictoryLabel,
} from "victory";
import React from "react";
import ReduxState from "../../../redux/state";
import { map, max, last, get, min } from "lodash";
import { connect } from "react-redux";
import LastOddsDot from "../LastOddsDot";
import { getOddDirection } from "../../../helpers/odds.helpers";
import { MAX_ODDS_X } from "../../../config";

interface OddsLineOwnProps {
  player: "home" | "away";
  color: string;
}

interface OddsLineStore {
  values: number[];
}

type OddsLineProps = OddsLineOwnProps & OddsLineStore;

const connector = (
  state: ReduxState,
  props: OddsLineOwnProps
): OddsLineStore => ({ values: map(state.odds, props.player) });

export default connect(connector)(function OddsLine(props: OddsLineProps) {
  const { values, color } = props;
  const data = values.map((y, x) => ({ x, y }));
  const [ready, setReady] = React.useState(false);
  const markers = values
    .map((y, x) => {
      if (x % 8 === 0) {
        return { x, y };
      }
      return { x, y: null };
    })
    .filter(({ y }) => y !== null);

  if (!ready) {
    setTimeout(() => {
      setReady(true);
    }, 1500);
  }

  if (data.length < 2) {
    return <span />;
  }

  const lastX = data.length - 1;
  const lastY = get(data[data.length - 1], "y");

  const nextData = [...data].slice(0, MAX_ODDS_X);

  return (
    <div style={{ height: 65, width: 1200 }}>
      <VictoryGroup
        height={65}
        width={1200}
        maxDomain={{ x: MAX_ODDS_X }}
        // @ts-ignore
      >
        <VictoryLine
          data={data}
          interpolation="natural"
          style={{
            data: {
              stroke: color,
              strokeWidth: 5,
              boxShadow: "-5px -5px 5px #888",
            },
          }}
          animate={{
            duration: 10000,
            onLoad: { duration: 3000 },
            onEnter: { duration: 10000 },
            onExit: { duration: 10000 },
          }}
        />
        {ready && (
          <VictoryScatter
            style={{
              data: { fill: "red", stroke: "white", strokeWidth: 2 },
            }}
            size={7}
            data={[{ x: lastX, y: lastY }]}
            labels={({ datum }) => `${getOddDirection(datum.y)}${datum.y}`}
            labelComponent={
              <VictoryLabel
                dx={20}
                textAnchor="start"
                verticalAnchor="middle"
                style={{ fill: "white" }}
              />
            }
          />
        )}
      </VictoryGroup>
    </div>
  );
});
