import React from "react";
import { decimalToODDS, getOddDirection } from "../../../helpers/odds.helpers";
import ReduxState from "../../../redux/state";
import { pick } from "lodash";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { getStorageValue } from "../../../helpers/storage.helpers";
import "./index.css";
import SmallChartsOdd from "../SmallChartsOdd";

interface SmallChartsOwnProps {
  names: any;
}

type SmallChartsStore = Pick<ReduxState, "lastOdds">;

type SmallChartsProps = SmallChartsOwnProps & SmallChartsStore;

const connector = (state: ReduxState): SmallChartsStore =>
  pick(state, ["lastOdds"]);

const withStore = connect(connector);

export default withStore(function SmallCharts(props: SmallChartsProps) {
  const { lastOdds, names } = props;

  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 100%)`;

  const innerStyle = {
    background: backgroundGradient,
  };

  return (
    <motion.div drag className="widget-wrapper" style={innerStyle}>
      <div className="logo-wrapper">
        <a
          href={localStorage.getItem("onyx-sponsor-site") as string}
          target="_blank"
        >
          <img
            alt="logo"
            src={localStorage.getItem("onyx-sponsor-logo") || "bet365.png"}
          />
        </a>
      </div>

      <div className="widget-main">
        <SmallChartsOdd name={names[0]} odds={lastOdds ? lastOdds.home : 0} />
        <SmallChartsOdd name={names[1]} odds={lastOdds ? lastOdds.away : 0} />
      </div>

      <div className="widget-footer">
        <span>live odds tracker</span>
      </div>
    </motion.div>
  );
});
