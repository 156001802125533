import ReduxState, { PlayerStats } from "../../../redux/state";
import { connect } from "react-redux";
import { pick } from "lodash";
import React from "react";
import { getPlayerPicture } from "../../../helpers/match.helpers";

interface PlayerStatsPicturesOwnProps {
  player: "home" | "away";
}

interface PlayerStatsPicturesStore {
  stats: PlayerStats;
}

type PlayerStatsPicturesProps = PlayerStatsPicturesOwnProps &
  PlayerStatsPicturesStore;

const connector = (
  state: ReduxState,
  props: PlayerStatsPicturesOwnProps
): PlayerStatsPicturesStore => {
  if (props.player === "home") {
    return { stats: state.homeStats };
  }
  return { stats: state.awayStats };
};

const withStore = connect(connector);

export default withStore(function PlayerStatsPictures(
  props: PlayerStatsPicturesProps
) {
  const src = getPlayerPicture(props.stats);
  if (!src) {
    return null;
  }
  return <img alt={props.player} src={src} />;
});
