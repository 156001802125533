import React from "react";

import "./Clock.css";

function addZero(n: number) {
  if (n < 10) {
    return `0${n}`;
  }
  return n.toString();
}

function calc(time: number) {
  const now = Date.now();
  const diff = now - time;
  const mins = Math.floor(diff / (1000 * 60));
  const diff2 = diff - mins * (1000 * 60);
  const secs = Math.ceil(diff2 / 1000);
  return [mins, secs];
}

export interface ClockProps {
  time: number;
}

export default function Clock(props: ClockProps) {
  const [mins, secs] = calc(props.time);

  const [minutes, setMinutes] = React.useState(mins);
  const [seconds, setSeconds] = React.useState(secs);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const [nextMins, nextSecs] = calc(props.time);
      setSeconds(nextSecs);
      setMinutes(nextMins);
    }, 1000);
    return () => clearTimeout(timer);
  }, [props, seconds]);

  return (
    <div className="Clock">
      <div className="Clock-digits">
        {addZero(minutes)}:{addZero(seconds)}
      </div>
    </div>
  );
}
