import React from "react";

import "./index.css";
import { getStorageValue } from "../../helpers/storage.helpers";
import ReduxState from "../../redux/state";
import { getMatchOpponents } from "../../helpers/match";
import { connect } from "react-redux";

interface LivePlayersStore {
  home: string;
  away: string;
}

type LivePlayersProps = LivePlayersStore;

const connector = (state: ReduxState): LivePlayersStore => {
  const { match } = state;
  if (!match) {
    return {
      home: "PLAYER 1",
      away: "PLAYER 2",
    };
  }
  const [home, away] = getMatchOpponents(match.name);
  return { home, away };
};

export default connect(connector)(function LivePlayers(
  props: LivePlayersProps
) {
  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 100%)`;

  const innerStyle = {
    background: backgroundGradient,
  };

  return (
    <div className="onyx-live-players-container" style={innerStyle}>
      <div>{props.home}</div>
      <div>{props.away}</div>
    </div>
  );
});
