import React from "react";
import { getOddDirection } from "../../../helpers/odds.helpers";
import "./index.css";

export interface SmallChartsOddProps {
  name: string;
  odds: number;
}

export default function SmallChartsOdd(props: SmallChartsOddProps) {
  const directionSymbol = getOddDirection(props.odds);
  let arrow;
  if (directionSymbol === "+") {
    arrow = "up";
  } else {
    arrow = "down";
  }

  return (
    <div className="player-wrapper">
      <span className="player-name">{props.name}</span>
      <span className="player-score">
        {Boolean(arrow) && (
          <img className="arrow-wrapper" alt="direction" src={`${arrow}.png`} />
        )}
        {directionSymbol}
        {props.odds}
      </span>
    </div>
  );
}
