import { PlayerStats } from "../redux/state";
import { keys, includes } from "lodash";

export function getMatchOpponents(name: string) {
  const [player1, player2] = name.split(" VS ");
  return [player1, player2];
}

export function getPlayerStats(stats: PlayerStats) {
  return keys(stats)
    .filter((stat) => stats[stat] !== null)
    .filter(
      (stat) =>
        !includes(
          [
            "created_at",
            "updated_at",
            "entity_type",
            "Name",
            "id",
            "Updated",
            "createdAt",
            "updatedAt",
          ],
          stat
        )
    )
    .reduce((next, stat) => ({ ...next, [stat]: stats[stat] }), {});
}

export function getPlayerPicture(player: PlayerStats) {
  return player.PhotoUrl || player.PICTURE || player.picture || "";
}
