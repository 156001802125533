import React from "react";

export default function Appbar() {
  return (
    <header className="main-header">
      <div className="header-left-wrapper">
        <div className="header-logo-wrapper">
          <img src="main-logo.png" className="main-logo" />
        </div>
        <ul className="main-menu-list">
          <li className="menu-item">
            <a className="menu-link">Home</a>
          </li>
          <li className="menu-item">
            <a className="menu-link">Schedule</a>
          </li>
          <li className="menu-item">
            <a className="menu-link menu-link--arrow menu-link--sports">
              Sports
              <ul className="side-menu-list">
                <li>
                  <span className="menu-link">MMA</span>
                </li>
                <li>
                  <span className="menu-link">BOX</span>
                </li>
                <li>
                  <span className="menu-link">FOOTBALL</span>
                </li>
              </ul>
            </a>
          </li>
        </ul>
      </div>
      <div className="header-right-wrapper">
        <div className="side-menu-container">
          <a className="menu-link menu-link--arrow menu-link--right">
            Menu
            <ul className="side-menu-list">
              <li>
                <span className="menu-link">Menu item</span>
              </li>
              <li>
                <span className="menu-link">Menu item</span>
              </li>
              <li>
                <span className="menu-link">Menu item</span>
              </li>
            </ul>
          </a>
        </div>
        <img className="search-icon" src="search.png" />
      </div>
    </header>
  );
}
