import React from "react";
import { useSpring } from "react-spring";
import { motion } from "framer-motion";

import SponsorLogo from "../layout/SponsorLogo";
import { trackRequest } from "../../api";
import Clock from "../layout/Clock/container";
import { getStorageValue } from "../../helpers/storage.helpers";

import "./index.css";
import LivePlayers from "../LivePlayers";
import LiveOddsChartsContainer from "../LiveOddsChartsContainer";

interface LiveOddsTrackerOwnProps {
  hovered: boolean;
  step: number;
  setStep: (step: number) => void;
  setIsBigWidget: (b: boolean) => void;
  isBigWidget: boolean;
}

type LiveOddsTrackerProps = LiveOddsTrackerOwnProps;

export default function LiveOddsTracker(props: LiveOddsTrackerProps) {
  const { hovered } = props;

  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 100%)`;

  const innerStyle = {
    background: backgroundGradient,
  };

  return (
    <motion.div
      drag
      className="onyx-main-popup"
      onClick={async () => {
        console.log("clicked!");
        await trackRequest();
      }}
    >
      <div className="onyx-main-popup-inner">
        <div style={innerStyle} className="onyx-live-sponsor-logo">
          <SponsorLogo />
        </div>

        <div className="onyx-live-chart">
          <LivePlayers />
        </div>

        <LiveOddsChartsContainer />
      </div>

      <div className="onyx-live-timer">
        <Clock />
        <span className="onyx-live-timer-title" style={innerStyle}>
          LIVE ODDS TRACKER
        </span>
      </div>
    </motion.div>
  );
}
