import { getStorageValue } from "../../helpers/storage.helpers";
import React from "react";
import { motion } from "framer-motion";

import "./index.css";
import OddsLine from "../data-visualisation/OddsLine";
import { range } from "lodash";

export default function LiveOddsChartsContainer() {
  const backgroundColor = getStorageValue("sponsor-color-background");
  const backgroundGradient = `linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 100%)`;

  const innerStyle = {
    background: backgroundGradient,
  };

  return (
    <div>
      <motion.div
        animate={{
          width: [0, window.innerWidth - 650],
        }}
        transition={{
          duration: 1.5,
          // ease: "easeInOut",
          // times: [0, 0.2, 0.5, 0.8, 1],
          // loop: Infinity,
          // repeatDelay: 1,
        }}
        className="onyx-live-odds-charts-container"
        style={innerStyle}
      >
        <div className="onyx-live-odds-charts-lines">
          <OddsLine player="home" color="white" />
          <OddsLine player="away" color="black" />
        </div>
      </motion.div>

      <div className="onyx-live-odds-charts-grid">
        {range(12).map((index) => (
          <div className="onyx-live-odds-charts-grid-y" key={index}>
            <div className="onyx-live-odds-charts-grid-label"></div>
            <div className="onyx-live-odds-charts-grid-y-lines" />
          </div>
        ))}
      </div>
    </div>
  );
}
