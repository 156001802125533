import {
  createStore as createReduxStore,
  combineReducers,
  applyMiddleware,
  compose,
  Store,
} from "redux";

import * as reducers from "./reducers";
import ReduxState from "./state";
import middlewares from "./middlewares";
import sagas from "./sagas/sagas";
import sagaMiddleware from "./sagas/middleware";
import { pollAction } from "./actions";
import { TICK_INTERVAL } from "../config";

interface Options {
  startPolling?: boolean;
}

export default function createStore(
  initialStore: Partial<ReduxState> = {},
  options: Options = {}
) {
  const rootReducer = combineReducers(reducers);

  const store: Store<ReduxState, any> = createReduxStore(
    rootReducer,
    initialStore,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  if (options.startPolling !== false) {
    setTimeout(() => {
      store.dispatch(pollAction());
    }, TICK_INTERVAL);
  }

  return store;
}
