import React, { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

let movewait;
let hideChart;
const useMove = (handleMouseEnter, handleMouseLeave) => {
  const [state, setState] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    e.persist();
    if (typeof movewait != "undefined") {
      clearTimeout(movewait);
      clearTimeout(hideChart);
    }

    movewait = setTimeout(function () {
      if (state.x !== e.clientX || state.y !== e.clientY) {
        handleMouseEnter && handleMouseEnter();
        setState((state) => ({ ...state, x: e.clientX, y: e.clientY }));
        hideChart = setTimeout(() => {
          handleMouseLeave && handleMouseLeave();
        }, 60000);
      }
    }, 200);
  };
  return {
    x: state.x,
    y: state.y,
    handleMouseMove,
  };
};

const Hook = ({ children, handleMouseEnter, handleMouseLeave }) => {
  const { x, y, handleMouseMove } = useMove(handleMouseEnter, handleMouseLeave);
  useScrollPosition(({ prevPos, currPos }) => {
    if (typeof hideChart != "undefined") {
      clearTimeout(hideChart);
    }
    handleMouseEnter && handleMouseEnter();
    hideChart = setTimeout(() => {
      handleMouseLeave && handleMouseLeave();
    }, 60000);
  });
  return <div onMouseMove={handleMouseMove}>{children}</div>;
};

export default Hook;
