import ReduxState from "../state";
import { setLastOddsAction } from "../actions";
import { ReduxActionType } from "../types";
import { decimalToODDS } from "../../helpers/odds.helpers";

type State = ReduxState["lastOdds"];

type Actions = ReturnType<typeof setLastOddsAction>;

const initialState: State = null;

export default function lastOddsReducer(
  state: State = initialState,
  action: Actions
): State {
  if (action.type === ReduxActionType.SET_LAST_ODDS) {
    return {
      ...action.payload.odds,
      home: decimalToODDS(action.payload.odds.home),
      away: decimalToODDS(action.payload.odds.away),
    };
  }
  return state;
}
