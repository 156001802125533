import React from "react";
import { pick, keys, startCase } from "lodash";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import "./index.css";

import ReduxState from "../../../redux/state";

type PlayerStatsChartsStore = Pick<ReduxState, "homeStats"> &
  Pick<ReduxState, "awayStats">;

type PlayerStatsChartsProps = PlayerStatsChartsStore;

const connector = (state: ReduxState): PlayerStatsChartsStore =>
  pick(state, ["homeStats", "awayStats"]);

const withStore = connect(connector);

function PlayerStatsCharts(props: PlayerStatsChartsProps) {
  return (
    <div className="onyx-player-stats-wrapper">
      <motion.div
        className="onyx-player-stats-container"
        animate={{
          width: [0, 800],
          opacity: [0, 1],
        }}
        transition={{ delay: 1 }}
      >
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ delay: 1.5 }}
          className="onyx-player-stats-inner"
        >
          <div className="ony-player-stats-inner-title">MATCH INFO</div>
          <div className="onyx-player-stats-inner-scrollable">
            {keys(props.homeStats)
              .filter((attr) => !Array.isArray(props.homeStats[attr]))
              .map((attr) => {
                const isIntLeft = Number.parseInt(props.homeStats[attr]);
                const isIntRight = Number.parseInt(props.awayStats[attr]);

                let leftMaxPerc = 100;
                let rightMaxPerc = 100;
                const scaleLeft = Math.random() + 1;
                const scaleRight = Math.random() + 1;
                if (
                  !isNaN(isIntLeft) &&
                  !isNaN(isIntRight) &&
                  typeof isIntLeft === "number" &&
                  typeof isIntRight === "number"
                ) {
                  const maxOne = Math.max(isIntLeft, isIntRight);
                  const minOne = Math.max(isIntLeft, isIntRight);
                  if (maxOne === isIntLeft) {
                    rightMaxPerc = ((minOne / maxOne) * 100) / scaleRight;
                    leftMaxPerc = 100 / scaleLeft;
                  } else {
                    rightMaxPerc = 100 / scaleRight;
                    leftMaxPerc = ((minOne / maxOne) * 100) / scaleLeft;
                  }
                } else {
                  leftMaxPerc /= scaleLeft;
                  rightMaxPerc /= scaleRight;
                }

                return (
                  <div key={attr} className="onyx-players-stats-charts">
                    <div className="onyx-players-stats-charts-values ">
                      <div
                        style={{
                          width: 100,
                          display: "block",
                        }}
                      >
                        <motion.div
                          style={{
                            float: "right",
                            marginRight: 5,
                          }}
                          className="onyx-players-stats-charts-bar onyx-players-stats-charts-bar--reverse"
                          animate={{
                            width: [0, leftMaxPerc],
                          }}
                          transition={{
                            delay: 1.5,
                            ease: "easeOut",
                            duration: 2,
                          }}
                        />
                      </div>
                      <div className="onyx-players-stats-value">
                        {props.homeStats[attr]}
                      </div>
                    </div>

                    <div className="onyx-players-stats-charts-attr">
                      {startCase(attr).toUpperCase()}
                    </div>

                    <div className="onyx-players-stats-charts-values ">
                      <div className="onyx-players-stats-value">
                        {props.awayStats[attr]}
                      </div>
                      <div
                        style={{
                          width: 100,
                          display: "block",
                        }}
                      >
                        <motion.div
                          style={{
                            float: "left",
                            marginRight: 5,
                          }}
                          className="onyx-players-stats-charts-bar"
                          animate={{
                            width: [0, rightMaxPerc],
                          }}
                          transition={{
                            delay: 1.5,
                            ease: "easeOut",
                            duration: 2,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default withStore(PlayerStatsCharts);
