import React from "react";
import styled, { keyframes } from "styled-components";
// @ts-ignore
import { zoomInDown } from "react-animations";

function SponsorLogo() {
  const zoomInDownAnimation = keyframes`${zoomInDown}`;

  const Logo = styled.img`
    animation: 2s ${zoomInDownAnimation};
  `;

  return (
    <div
      className="onyx-sponsor-logo"
      style={{
        borderRightColor:
          localStorage.getItem("onyx-sponsor-color-stroke") || "#FFFFFF",
      }}
    >
      <a
        href={localStorage.getItem("onyx-sponsor-site") as string}
        target="_blank"
      >
        <Logo
          src={localStorage.getItem("onyx-sponsor-logo") || "bet365.png"}
          alt="bet365"
          style={{
            maxHeight: 120,
            objectFit: "cover",
            maxWidth: 200,
          }}
        />
      </a>
    </div>
  );
}

export default React.memo(SponsorLogo);
