import ReduxState from "../state";
import { setMatchAction } from "../actions";
import { ReduxActionType } from "../types";

type State = ReduxState["match"];

type Actions = ReturnType<typeof setMatchAction>;

const initialState: State = null;

export default function matchReducer(
  state: State = initialState,
  action: Actions
): State {
  if (action.type === ReduxActionType.SET_MATCH) {
    return action.payload.match;
  }
  return state;
}
