import React from "react";
import { motion } from "framer-motion";

import "./index.css";
import SponsorLogo from "../SponsorLogo";
import PlayerStatsCharts from "../../data-visualisation/PlayerStatsCharts";
import PlayerStatsNames from "../PlayerStatsNames";
import PlayerStatsPictures from "../PlayerStatsPictures";

export default function PlayersStats() {
  return (
    <div className="onyx-player-stats">
      <div className="onix-player-stats-logo">
        <SponsorLogo />
      </div>
      <PlayerStatsCharts />
      <motion.div
        className="onyx-player-stats-player-picture"
        transition={{ ease: "easeOut", duration: 1 }}
        animate={{
          left: [-500, 100],
        }}
        style={{ justifyContent: "flex-end" }}
      >
        <PlayerStatsPictures player="home" />
      </motion.div>
      <motion.div
        className="onyx-player-stats-player-picture"
        transition={{ ease: "easeOut", duration: 1 }}
        animate={{
          right: [-500, 100],
        }}
      >
        <PlayerStatsPictures player="away" />
      </motion.div>
      <PlayerStatsNames />
    </div>
  );
}
