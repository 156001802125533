import { takeLatest, put, call } from "redux-saga/effects";
import { ReduxActionType } from "../types";
import { getLiveOddsRequest, getMatchAndSponsorRequest } from "../../api";
import {
  setLastOddsAction,
  pollAction,
  setMatchAction,
  setHomeStatsAction,
  setAwayStatsAction,
} from "../actions";
import { TICK_INTERVAL } from "../../config";
import {
  setSponsorSettings,
  setMatchSettings,
} from "../../helpers/storage.helpers";

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function* longPollSaga() {
  try {
    const odds = yield call(getLiveOddsRequest);

    yield put(setLastOddsAction(odds));
  } catch (error) {}

  yield call(wait, TICK_INTERVAL);
  yield put(pollAction());
}

function* getMatchAndSponsorSaga() {
  const { match, sponsor, maybeThePlayerOne, maybeThePlayerTwo } = yield call(
    getMatchAndSponsorRequest
  );
  setSponsorSettings(sponsor);
  setMatchSettings(match);
  yield put(setHomeStatsAction(maybeThePlayerOne));
  yield put(setAwayStatsAction(maybeThePlayerTwo));
  yield put(setMatchAction(match));
}

export default function* () {
  yield takeLatest(ReduxActionType.POLL, longPollSaga);
  yield takeLatest(
    ReduxActionType.GET_MATCH_AND_SPONSOR,
    getMatchAndSponsorSaga
  );
}
